document.addEventListener('DOMContentLoaded', function(e) {

    //Change text language wpml menu
    let $languageLinks = document.querySelectorAll('.wpml-menu .wpml-ls-native');

    if($languageLinks) {
        $languageLinks.forEach(function(link) {
            if (link.textContent === 'Español') {
                link.textContent = 'ES';
            } else if (link.textContent === 'English') {
                link.textContent = 'EN';
            }
        });
    }


    //Add class to wpml menu click event ES
    // let $menuWPML = document.getElementById("menu-menu_wpml");

    // if ($menuWPML) {
    //     $menuWPML.addEventListener("mouseenter", () => {
    //         $menuWPML.classList.add("open");
    //     });
    
    //     $menuWPML.addEventListener("mouseleave", () => {
    //         $menuWPML.classList.remove("open");
    //     });
    // }
    
    //Add class to wpml menu click event EN
    // let $menuWPMLEN = document.getElementById("menu-menu_wpml-ingles-1");

    // if ($menuWPMLEN) {
    //     $menuWPMLEN.addEventListener("mouseenter", () => {
    //         $menuWPMLEN.classList.add("open");
    //     });
    
    //     $menuWPMLEN.addEventListener("mouseleave", () => {
    //         $menuWPMLEN
    //         .classList.remove("open");
    //     });
    // }


    //Add class to main menu click event
    let $menuItemsWithSubMenu = document.querySelectorAll('.menu-menu_principal-container > ul > li > ul, .menu-menu_principal-ingles-container > ul > li > ul');

    $menuItemsWithSubMenu.forEach(function(subMenu) {
        let parentLi = subMenu.parentElement;
        if(parentLi) var link = parentLi.querySelector('a');

        if(link) {
            link.addEventListener('click', function(event) {
                event.preventDefault();
                parentLi.classList.toggle('open');
            });
        }
    });


    //Event close menu on click outside
    document.addEventListener('click', e=>  {
        if (e.target.closest('.menu-menu_principal-container .sub-menu') || e.target.matches('.menu-menu_principal-container ul li a')) {
            return; // No hacer nada si el clic ocurrió dentro de un submenú
        }
    
        // Cerrar todos los menús abiertos
        let openMenus = document.querySelectorAll('.menu-menu_principal-container > ul > li.open');
        openMenus.forEach(function(menu) {
            menu.classList.remove('open');
        });
    });

    //Open form contact header
    const $btnOpenForm = document.querySelector(".link-form-contact");
    if($btnOpenForm) {
        $btnOpenForm.addEventListener("click", e => {
            $contactForm = document.querySelector(".block__contact");
            if($contactForm) {
                $contactForm.classList.add("open");

            }
        })
    }


    //Close form
    const $btnCloseForm = document.querySelector(".cross_close");
    if($btnCloseForm) {
        $btnCloseForm.addEventListener("click", e => {
            $contactForm = document.querySelector(".block__contact");
            if($contactForm) {
                $contactForm.classList.remove("open");
            }
        })
    }

    if(window.innerWidth <= 1050) {

        //Open menu mobile
        const $btnOpenMenuMobile = document.querySelector(".main-menu-mobile-link");
        if($btnOpenMenuMobile){
            $btnOpenMenuMobile.addEventListener("click", e => {
                const $menuMobile = document.querySelector(".menu-mobile");
                if($menuMobile) $menuMobile.classList.toggle("active");  
                const $icos = $btnOpenMenuMobile.querySelectorAll("svg");
                if($icos) {
                    $icos.forEach(el=> {
                        if (el.style.display === "none") {
                            el.style.display = "block";
                          } else {
                            el.style.display = "none";
                          }
                    })
                }
                
            })
        }
    
        //Open submenu mobile
        const $menuHasChildren = document.querySelectorAll(".menu-mobile .menu-item-has-children");
        if($menuHasChildren) {
            $menuHasChildren.forEach(el=> {
                el.addEventListener("click", function(event) {
                    event.stopPropagation();
                    const submenu = this.querySelector('.sub-menu');
                    if (submenu) submenu.classList.toggle("open");
                    
                })
            })
        }
    }

});
